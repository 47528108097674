import { Component, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import { URLS } from '@/shared/backend';
import DateUtils from '@/shared/utils/DateUtils';
import AllocationExportDialog from '@/components/dialogs/allocationExportDialog/allocationExportDialog.vue';
import RouterUtils from '@/shared/utils/RouterUtils';
import { AllocationSetting } from '@/shared/model/allocationSetting';

const logger = new Logger('allocation');
const allocationsExportModule = namespace('allocationsExport');
const allocationSettingModule = namespace('allocationSetting');

@Component({
  components: { AllocationExportDialog },
})
export default class AllocationExportView extends Vue {
  @allocationsExportModule.Action('getAllocationsExports')
  private actionGetAllocationsExports!: any;
  @allocationsExportModule.Getter('getAllocationsExports')
  private getAllocationsExports!: any;

  @allocationsExportModule.Action('createAllocationsExport')
  private actionCreateAllocationsExport!: any;
  @allocationsExportModule.Action('markAsDownloadedAllocationExport')
  private actionMarkAsDownloadedAllocationExport!: any;

  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;

  private exports: any = {
    searchParams: {},
  };

  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('month'), value: 'monthTimeStamp' },
      { text: this.$t('info'), value: 'officeName' },
      { text: this.$t('create_date'), value: 'createdAt' },
      {
        text: this.$t('download'),
        value: 'actions',
        sortable: false,
        width: '1%',
      },
    ];
    return headers;
  }

  onClickDownload(item: any) {
    this.openFile(item.allocationsExportFileId, item.allocationsExportFileName, item.allocationsExportId);
  }

  created() {
    this.actionGetAllocationsExports();
  }

  dateISOToLocalMonthYear(isoDate: any) {
    return DateUtils.formatDateMonth(isoDate);
  }

  dateISOToLocal(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale, false, true);
  }

  private openFile(fileId: string, shortFilename: string, exportId: string) {
    const fileUrl = `${process.env.VUE_APP_BASE_PATH_REDIRECT}${URLS.userFile}/download/${fileId}`;
    this.download(fileUrl, shortFilename);
    this.actionMarkAsDownloadedAllocationExport(exportId).then(() => {
      this.actionGetAllocationsExports();
    });
  }

  private download(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  private open(item: any) {
    this.$router.push({
      name: 'allocation-details',
      params: {
        accountExportDate: this.dateISOToLocalMonthYear(item.monthTimeStamp),
        exportId: item.allocationsExportId,
        exportFileId: item.allocationsExportFileId,
        exportFileName: item.allocationsExportFileName,
      },
    });
  }

  //#region : AllocationExportDialog
  private isSaveBtnLoading = false;

  onClickAdd() {
    this.itemEditDialog.model = {};
    this.itemEditDialog.show = true;
  }

  async onClickEditt(item: any) {
    this.itemEditDialog.model = {};
    this.itemEditDialog.show = true;
  }

  itemEditDialog = {
    show: false,
    model: {},
  };

  async itemEditDialogOnClose(account: any) {
    this.itemEditDialog.show = false;
  }

  private translateDatevToNumber(date: string) {
    switch (date) {
      case 'custom':
        return 1; //CSV
      case 'datev':
        return 2; //DATEV
      case 'loco_soft':
        return 3; //LOCO SOFT
      default:
        return 1;
    }
  }

  async itemEditDialogOnExport(branch: string) {
    this.itemEditDialog.show = false;
    this.actionCreateAllocationsExport({
      timezoneOffet: new Date().getTimezoneOffset(),
      branchCode: branch == '' ? RouterUtils.emptyGuid : branch,
      exportType: this.translateDatevToNumber(this.allocationSetting.exportType),
    }).then(() => {
      let oldItemsPerpage = this.getAllocationsExports.searchParams.dataOption.itemsPerPage;
      this.getAllocationsExports.searchParams.dataOption = {
        sortBy: ['createdAt'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: oldItemsPerpage,
      };
      this.actionGetAllocationsExports().then(() => {});
    });
  }
  //#endregion
  getAllocationsExportsOnOptionsUpdate(searchOptions: any) {
    this.$nextTick(() => {
      this.getAllocationsExports.searchParams.dataOption = searchOptions;
      this.actionGetAllocationsExports();
    });
  }
}
